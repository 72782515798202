* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.card-container {
    height: 100%;
    display: flex;
    margin: 3rem 7vw 0 7vw;
    flex-wrap: wrap;
    justify-content: center;
}

.flip-card-dept {
    position: relative;
    margin: 0 1rem 2rem;
    top: 0;
    left: 0;
    perspective: 1000px;
    border-radius: 2rem;
    width: 14rem;
    height: 20rem;
}

h1 {
    color: white;
    text-align: center;
    font-size: 4rem;
    padding-top: 3rem;
}

@media only screen and (max-width: 968px) {
    h1{
        font-size: 3rem;
    }
}
@media only screen and (max-width: 392px) {
    h1{
        font-size: 2rem;
    }
}