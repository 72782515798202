.main-div{
    display: flex;
    justify-content: center;
    margin-top: 3rem;
    width: 100%;
}

.logreg-main{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: calc(100% - 10vw);
    height: calc(100vh - 20vh);
}

h2{
    font-size: 2rem;
    margin: 0% 0% 1.7% 0%;
}


p{
    margin: 0% 0% 1% 0%;
}

.logreg-out-left{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;
}

.login-image{
    width: 60vw;
    height: 100vh;
    /* border-radius: 5px; */
}

.credentials-div{
    padding: 2% 4%;
    width: 40%;
    height: 100%;
    border-radius: 20px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
    background-color: transparent;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.credentials-div div{
    color:white;
    display: flex;
    flex-direction: column;
    margin-top: 0%;   
    position: relative;
    margin-bottom: 1.5rem;
}

.credentials-div div input{
    height: 2rem;
    font-size: 80%;
    padding: 2px;
    border-radius: 5px;
    outline: none;
    border: none;
}


@media screen and (max-width: 1024px){

    .main-div{
        margin-top: 4rem;
        width: 100%;
        height: 100%;
    }

    .logreg-main{
        flex-direction: column;
        width: 100%;
        justify-content: start;
    }

    .logreg-out-left{
        width: 100%;
    }

    .login-image{
        display: none;
        width: 100%;
        height: 100vh;
    }

    .credentials-div::before{
            content: "";
            background: url('../images/Spring.png') no-repeat center center/cover;
            background-size: cover;
            text-align: center;
            position: absolute;
            top: 0;
            left: 0;
            width: 100vw;  
            height: 100vh;
            z-index: -1;
            opacity: 0.4; /* Adjust opacity value as needed */
    }

    .credentials-div{
        height: 90%;
        width: 70%;
        box-shadow: none;
    }

}