.flip-card {
    perspective: 1000px;
    border-radius: 2rem;
    width: 14rem;
    height: 20rem;
  } 
  
  .flip-card-inner {
    transition: transform 0.3s;
    transform-style: preserve-3d;
    width: 100%;
    height: 100%;
    border-radius: 2rem;
  }
  
  .flip-card-front,
  .flip-card-back {
    box-shadow: 0 0 10px #0000003f;
    border-radius: 1rem;
    position: absolute;
    backface-visibility: hidden;
    width: 100%;
    height: 100%;
    padding: 0.8rem;
  }
  
  .flip-card-back {
    transform: rotateY(180deg);
  }
  
  .mem-image {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background-size: cover;
    background-position: center;
    outline: 3px solid black;
    display: flex;
    align-items: end;
  }

  .details {
    background: linear-gradient(0deg, rgba(0,0,0,1) 10%, rgba(0,0,0,0) 100%);
    color: white;
    width: 100%;
    padding: 0.6rem;
  }

  .card-name {
    font-size: 1.4rem;
  }

  .card-designation {
    font-size: 0.8rem;
    padding: 2px 0;
    font-weight: 300;
  }

  .opacity {
    background-color: black;
    opacity: 0.2;
  }


  @media only screen and (max-width: 798px) {
    .flip-card {
      width: 14rem;
      height: 20rem;
    }
    .flip-card-front,
    .flip-card-back {
      /* border-radius: 1rem; */
      padding: 0.6rem;
    }
  }

  @media only screen and (max-width: 550px) {
    .flip-card {
      width: 14rem;
      height: 20rem;
    }
    .flip-card-front,
    .flip-card-back {
      border-radius: 0.6rem;
      padding: 0.5rem;
    }

    .mem-image {
      border-radius: 4px;
    }
  }
  @media only screen and (max-width: 460px) {
    .flip-card {
      width: 12.6rem;
      height: 18rem;
    }
    .flip-card-front,
    .flip-card-back {
      border-radius: 0.6rem;
      padding: 0.5rem;
    }

    .mem-image {
      border-radius: 4px;
    }
  }
  @media only screen and (max-width: 392px) {
    .flip-card {
      width: 12.6rem;
      height: 18rem;
    }
    .flip-card-front,
    .flip-card-back {
      border-radius: 0.6rem;
      padding: 0.5rem;
    }

    .mem-image {
      border-radius: 4px;
    }
  }